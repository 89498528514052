var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shop-content-wrap"},[_c('div',{staticClass:"d-custom-flex justify-space-between align-items-center"},[_c('div',{staticClass:"shop-header d-custom-flex"},[_c('div',{staticClass:"ham-menu-sm d-none"},[_c('v-btn',{staticClass:"mr-3 my-0",attrs:{"flat":"","icon":""},on:{"click":_vm.toggleEcommerceSidebar}},[_c('v-icon',{staticClass:"grey--text"},[_vm._v("menu")])],1)],1),_c('div',{staticClass:"app-selectbox-sm"},[_c('ais-sort-by-selector',{attrs:{"indices":[
							{ name: 'ikea', label: 'Default'},
							{ name: 'ikea_price_asc', label: 'Lowest price' },
							{ name: 'ikea_price_desc', label: 'Highest price' }
						]}})],1)]),_c('ais-stats')],1),_c('ais-results',{staticClass:"layout row wrap mb-4",attrs:{"results-per-page":12},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var result = ref.result;
return [_c('product-item',{attrs:{"data":result}})]}}])}),_c('div',{staticClass:"pagination-wrap"},[_c('ais-pagination',{staticClass:"mb-4",attrs:{"classNames":{
				'ais-pagination': 'pagination',
				'ais-pagination__item': 'pagination__item',
				'ais-pagination__item--active': 'pagination__item--active primary',
				'ais-pagination__item--previous': 'pagination__navigation',
				'ais-pagination__item--next': 'pagination__navigation',
				'ais-pagination__item--disabled': 'disabled'

			}},on:{"page-change":_vm.onPageChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }